import './App.css';
import Welcomepage from './Components/Welcomepage';

function App() {
  return (
    <div className="App">
      <Welcomepage/>
    </div>
  );
}

export default App;
