import React from "react";
import { useState, useEffect, useRef } from "react";
import "./welcomepage.css";
import audioo from "../Audio/Hi I am an A I Agent.wav";
import Startele from '../Assets/StarteleLogo.png';
import Star from '../Assets/Star.png';
import Modal from "@mui/material/Modal";
import { Box, Button, OutlinedInput, useForkRef } from "@mui/material";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bars, DNA } from "react-loader-spinner";
import { v4 as uuidv4 } from 'uuid';

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "white",
  boxShadow: 24,
  borderRadius: "10px",
  border: "0px",
  p: 3,
};

const Welcomepage = () => {
  const [showMike, setShowMike] = useState(false);

  const [OpenForm, setOpenForm] = useState(false);
  const [FullName, setFullName] = useState("");
  const [Email, setEmail] = useState("");

  const [startProcess, setStartProcess] = useState(false)
  const [showText, setShowText] = useState(true)
  const [showDna, setShowDna] = useState(false)

  const HandleSubmitForm = () => {
    setFullName("")
    setEmail("")
    setOpenForm(false)
    toast.info("Thank you for contacting us !  We will get back to you soon.", {
      position: "top-left",
      autoClose: 2000,
    });
  }

  const handleOpenForm = () => {
    setOpenForm(true)
  }

  const handleCloseForm = () => {
    setOpenForm(false)
    setFullName("")
    setEmail("")
  }

  let mediaRecorder;
  let recordedChunks = [];

  const playAudio1 = () => {
    setStartProcess(true)
    setShowText(false)
    setShowDna(true)

    const myHeaders = new Headers();
    myHeaders.append("Accept", "*/*");
    myHeaders.append("User-Agent", "Thunder Client (https://www.thunderclient.com)");
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "text": "Hi , I am an AI agent, How may I help you ?",
      "filename": "welcomeMsg"
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch("https://ai.telemo.io:8004/bot/api/createAudioFile/", requestOptions)
      .then((response) => response.blob())
      .then((audioBlob) => {
        const audioUrl = URL.createObjectURL(audioBlob);
        const audio = new Audio(audioUrl);

        audio.play();

        audio.onended = function () {
          setShowDna(false)
          StartRecording()
        };
      })
  };


  const playAudio2 = () => {
    window.location.reload()
    setStartProcess(false)
  }

  const StartRecording = () => {
    setShowDna(false)
    setShowMike(true)  // To show that it is now recording
    console.log("--------------------recording started-------------------->");
    recordedChunks = [];
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        mediaRecorder = new MediaRecorder(stream);

        mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            recordedChunks.push(event.data);
          }
        };

        setTimeout(() => {
          StopRecording()
          mediaRecorder.onstop = () => {
            const audioBlob = new Blob(recordedChunks, { type: "audio/wav" });
            AudioToText(audioBlob);
          };
        }, 6000);

        mediaRecorder.start();
      })
  };

  const AudioToText = (audioBlob) => {
    const myHeaders = new Headers();

    myHeaders.append("Authorization", "Bearer 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");

    myHeaders.append("Cookie", "_cf_bm=7tRRh9oRkvetbASsX3zxG.Fxx8pmRe3uV4xe5YMZKXQ-1712149499-1.0.1.1-W01qVimWcW.aorSMipTC_WaOv3p1C9H5S7iuM35LSuFHh9p2okjaKK3owS2cYlUcRgV6ujuuZGCccvycQvgi6Q; cfuvid=1KNAf5HKDVPn7v.MzVSJvNqopt0rmd.I7OSHfkidlNE-1712149499876-0.0.1.1-604800000; __cf_bm=whTy88XU46q.yZI8LTgtdfscq5FVsKOsisgzBDSE5G8-1712152027-1.0.1.1-9Hl8HkUl1LFm.ccvuG45ZBYMlhS9iHdyJwK4PrxvTQTzMpzThzZ_72L4Ybg5MEX.9sISv_8SYERkiV6tmMpKmw; _cfuvid=mmVjnqj.NLf90PY6XQ4XVl_nxJ8eluEfAqMhKOI9xV4-1712152027353-0.0.1.1-604800000");

    const formdata = new FormData();
    formdata.append("file", audioBlob, "audio.wav");
    formdata.append("model", "whisper-1");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow"
    };

    fetch("https://api.openai.com/v1/audio/transcriptions", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result, 'This is the speech to text result')
        ChatBoatResponse(result.text, NewConversationId)   // This function will give response from the chat boat
      })
  }

  let NewConversationId = uuidv4();

  const ChatBoatResponse = (answer, conversationId) => {

    const myHeaders = new Headers();
    myHeaders.append("Accept", "*/*");
    myHeaders.append("User-Agent", "Thunder Client (https://www.thunderclient.com)");

    const formdata = new FormData();
    formdata.append("context_id", "5d1b91be-44b3-4981-9a82-c6daac35e89a");
    formdata.append("add_on", "");
    formdata.append("conversation_id", conversationId);
    formdata.append("message", answer);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow"
    };

    fetch("https://ai.telemo.io:8004/bot/api/chatAudio/", requestOptions)
      .then((response) => response.blob())
      .then((audioBlob) => {

        const audioUrl = URL.createObjectURL(audioBlob);
        const audio = new Audio(audioUrl);

        audio.playbackRate = 1.25; // Set the playback rate to 1.25x

        audio.play();

        audio.onended = function () {
          StartRecording()
        };
      })
  }

  const StopRecording = () => {
    console.log("-----------------recording stopped-------------------");
    if (mediaRecorder.state === "recording") {
      mediaRecorder.stop();
    }
    setShowMike(false)
    setShowDna(true)
  };

  return (
    <>
      <div id="main" >
        <div className="navbar">
          <a href="https://www.startelelogic.com/" target="_blank" >
            <img src={Startele} id="navbarLogo" />
          </a>

          <div id="navbarMenu">
            <a href="https://www.startelelogic.com/about-us" target="_blank" >About Us</a>
            <a href="https://www.startelelogic.com/generative-ai-development-company" target="_blank" >AI Services</a>
            <a href="https://www.startelelogic.com/software-development-company" target="_blank" >More Services</a>
            <a href="https://www.startelelogic.com/contact" target="_blank" >Contact Us</a>
          </div>
        </div>

        <div className="S1" >
          <div id="S1-box1">
            <h1> Artificial Intelligence Voice Assistant </h1>
            <p>
              Our generative AI development, based on microservices architecture, empowers organizations to tap into AI's full potential, creating genuinely intelligent systems and software.
            </p>
            <div>
              <button className="btn" id="btn1" onClick={handleOpenForm}  >
                Contact Sales
              </button>
              <button className="btn" id="btn2" onClick={handleOpenForm} >
                Request Demo
              </button>
            </div>
          </div>
          <div id="S1-box2">
            <div id="trialBox" >
              <button onClick={playAudio1} disabled={startProcess} id="trial" >
                {showMike &&
                  <Bars
                    height="60"
                    width="60"
                    color="#9c88ff"
                    ariaLabel="bars-loading"
                    position="absolute"
                    visible={true}
                  />
                }

                {/* {
                  showText &&
                  <p>
                    Try it out
                  </p>
                } */}

                {showDna &&
                  <DNA
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="drenderna-loading"
                    wrapperClass="dna-wrapper"
                  />
                }
              </button>
            </div>
            <br /><br />
            {
              startProcess &&
              <button className="btn" id="endButton" style={{ padding: '8px 15px', fontSize: '14px' }}
                onClick={playAudio2} disabled={!startProcess}
              >
                Start again ....
              </button>
            }
          </div>
        </div>

        <div className="footer">
          <p style={{
            fontSize: '14px',
            color: 'white',
            marginLeft: '20px',
            fontWeight: '100'
          }} >
            Powered by <a href="https://www.startelelogic.com/">
              <span style={{ color: '#3498db', fontWeight: '500', fontSize: '18px', margin: '0px 3px' }} >Startelelogic</span>
              <img src={Star} style={{ height: '18px' }} />
            </a>
          </p>
        </div>
      </div >


      {/* MODAL FOR THE FORM */}
      <Modal
        open={OpenForm}
        onClose={handleCloseForm}
      >
        <Box sx={ModalStyle}>
          <div
            className="formBox"
            style={{
              width: "100%",
              height: "auto",
            }}
          >
            <div className="ModalInputSelect">
              <h3
                style={{
                  fontSize: "18px",
                  width: "30%",
                  marginLeft: "10px",
                }}
              >
                Enter Name :
              </h3>
              <div
                className="inputInfoBox "
                style={{ width: "70%", margin: "0px 0px 0px 0px" }}
              >
                <div style={{ display: "flex" }}>
                  <p id="inputInfoTopText">Name </p>
                </div>
                <OutlinedInput
                  type="text"
                  inputProps={{ tabIndex: "10" }}
                  value={FullName}
                  onChange={(e) => setFullName(e.target.value)}
                  style={{ width: "100%", height: "40px" }}
                  placeholder="Please enter your name"
                />
              </div>
            </div>

            <div className="ModalInputSelect">
              <h3
                style={{
                  fontSize: "18px",
                  width: "30%",
                  marginLeft: "10px",
                }}
              >
                Enter Email ID :
              </h3>
              <div
                className="inputInfoBox "
                style={{ width: "70%", margin: "0px 0px 0px 0px" }}
              >
                <div style={{ display: "flex" }}>
                  <p id="inputInfoTopText">Email ID </p>
                </div>
                <OutlinedInput
                  type="text"
                  inputProps={{ tabIndex: "10" }}
                  value={Email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ width: "100%", height: "40px", }}
                  placeholder="Please enter your Email ID"
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "15px",
              }}
            >
              <Button
                onClick={HandleSubmitForm}
                style={{
                  color: "#8224e3",
                  marginRight: "20px",
                }}
                variant="outlined"
                color="inherit"
              >
                Submit
              </Button>
              <Button
                onClick={handleCloseForm}
                style={{ color: "red" }}
                variant="outlined"
                color="inherit"
              >
                Cancel
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default Welcomepage;
